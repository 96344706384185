var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[(
      _vm.$store.getters.can('cs.tarifarios.createTarifasProduccion') ||
      _vm.$store.getters.can('cs.tarifarios.editTarifasProduccion')
    )?_c('div',{staticClass:"col-md-12 text-right mb-2"},[_c('div',{staticClass:"btn-group"},[(_vm.$store.getters.can('cs.tarifarios.createTarifasProduccion'))?_c('button',{staticClass:"btn btn-primary",attrs:{"data-target":"#Modal_trabajos","data-toggle":"modal"},on:{"click":function($event){return _vm.getFormTarifa()}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(
          _vm.$store.getters.can('cs.tarifarios.createTarifasProduccion') ||
          _vm.$store.getters.can('cs.tarifarios.editTarifasProduccion')
        )?_c('button',{staticClass:"btn btn-success",attrs:{"data-target":"#Modal_formato_masivo","data-toggle":"modal"},on:{"click":function($event){return _vm.getFormFormato()}}},[_c('i',{staticClass:"fa fa-file-excel"})]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-md-12"},[(
        _vm.$parent.datos_tarifario &&
        _vm.$parent.datos_tarifario.tarifas_trabajos.length > 0
      )?_c('table',{staticClass:"table table-bordered table-responsive table-striped table-hover table-sm mb-0"},[_vm._m(0),_c('tbody',_vm._l((_vm.$parent.datos_tarifario.tarifas_trabajos),function(tarifa){return _c('tr',{key:tarifa.id},[_c('td',[_vm._v(_vm._s(tarifa.nombre))]),_c('td',[_vm._v(_vm._s(tarifa.nTipoVehEquipo))]),_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(tarifa.equipo_type == 1 ? tarifa.nTipoVh + " - " + tarifa.nTipoRemolque : tarifa.nTipoEquipo)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tarifa.nTipoTiempoTarifa))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(tarifa.nUnidadMedida))]),_c('td',[_vm._v(_vm._s(tarifa.nClaseTarifa))]),_c('td',{staticClass:"text-center text-nowrap"},[(tarifa.detalles.length > 0)?_c('div',[_vm._v(" "+_vm._s(tarifa.detalles[0].fecha_fin ? `${tarifa.detalles[0].fecha_ini} - ${tarifa.detalles[0].fecha_fin}` : `${tarifa.detalles[0].fecha_ini}`)+" ")]):_vm._e()]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(tarifa.detalles.length > 0 ? _vm.formatCurrency("es-CO", "COP", 2, tarifa.detalles[0].valor) : "")+" ")]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:[
                tarifa.estado == 1 ? 'badge-success' : 'badge-danger',
              ]},[_vm._v(" "+_vm._s(tarifa.nEstado)+" ")])]),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"btn-group"},[(
                  _vm.$store.getters.can('cs.tarifarios.editTarifasProduccion') &&
                  _vm.$parent.estado_tarifario == 1
                )?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"data-target":"#Modal_trabajos","data-toggle":"modal"},on:{"click":function($event){return _vm.getFormTarifa(tarifa)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(
                  _vm.$store.getters.can('cs.tarifarios.editTarifasProduccion')
                )?_c('button',{staticClass:"btn btn-sm bg-info",attrs:{"data-target":"#Modal_trabajos_detalles","data-toggle":"modal"},on:{"click":function($event){return _vm.getDataDet(tarifa)}}},[_c('i',{staticClass:"fas fa-list"})]):_vm._e()])])])}),0)]):_c('div',{staticClass:"callout callout-warning text-muted m-0"},[_c('h5',{staticClass:"m-0"},[_vm._v("No hay tarifas registradas!")])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"Modal_trabajos"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(_vm._s(_vm.modal_title))]),_c('button',{ref:"closeModalTrabajo",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body pb-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_tarifa.nombre),expression:"form_tarifa.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_tarifa.nombre.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"text","disabled":(!_vm.$store.getters.can(
                    'cs.tarifarios.createTarifasProduccion'
                  ) &&
                    !_vm.form_tarifa.id) ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.editTarifasProduccion'
                  ) &&
                    _vm.form_tarifa.id)},domProps:{"value":(_vm.form_tarifa.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_tarifa, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Tipo Vehículo/Equipo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_tarifa.equipo_type),expression:"form_tarifa.equipo_type"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_tarifa.equipo_type.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"disabled":(!_vm.$store.getters.can(
                    'cs.tarifarios.createTarifasProduccion'
                  ) &&
                    !_vm.form_tarifa.id) ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.editTarifasProduccion'
                  ) &&
                    _vm.form_tarifa.id)},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_tarifa, "equipo_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.resetVehEquip()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.equipos_types),function(equipo_type){return _c('option',{key:equipo_type.numeracion,domProps:{"value":equipo_type.numeracion}},[_vm._v(" "+_vm._s(equipo_type.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Vehículos / Equipos Izaje")]),(_vm.form_tarifa.equipo_type == 1)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_tarifa.equipo_id),expression:"form_tarifa.equipo_id"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_tarifa.equipo_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"disabled":(!_vm.$store.getters.can(
                    'cs.tarifarios.createTarifasProduccion'
                  ) &&
                    !_vm.form_tarifa.id) ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.editTarifasProduccion'
                  ) &&
                    _vm.form_tarifa.id)},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_tarifa, "equipo_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_vehiculos),function(tip_veh,index){return _c('option',{key:index,domProps:{"value":tip_veh}},[_vm._v(" "+_vm._s(tip_veh.nombre)+" ")])})],2):_vm._e(),(_vm.form_tarifa.equipo_type == 2)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_tarifa.equipo_id),expression:"form_tarifa.equipo_id"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_tarifa.equipo_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"disabled":(!_vm.$store.getters.can(
                    'cs.tarifarios.createTarifasProduccion'
                  ) &&
                    !_vm.form_tarifa.id) ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.editTarifasProduccion'
                  ) &&
                    _vm.form_tarifa.id)},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_tarifa, "equipo_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_equipos),function(equip){return _c('option',{key:equip.numeracion,domProps:{"value":equip.numeracion}},[_vm._v(" "+_vm._s(equip.descripcion)+" ")])})],2):_vm._e()]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Tipo Tiempo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_tarifa.tipo_tiempo_tarifa),expression:"form_tarifa.tipo_tiempo_tarifa"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_tarifa.tipo_tiempo_tarifa.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"disabled":(!_vm.$store.getters.can(
                    'cs.tarifarios.createTarifasProduccion'
                  ) &&
                    !_vm.form_tarifa.id) ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.editTarifasProduccion'
                  ) &&
                    _vm.form_tarifa.id)},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_tarifa, "tipo_tiempo_tarifa", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipo_tiempos_tarifas),function(tipo_tiempo_tarifa){return _c('option',{key:tipo_tiempo_tarifa.numeracion,domProps:{"value":tipo_tiempo_tarifa.numeracion}},[_vm._v(" "+_vm._s(tipo_tiempo_tarifa.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Unidad Medida")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_tarifa.unidad_medida),expression:"form_tarifa.unidad_medida"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_tarifa.unidad_medida.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"disabled":(!_vm.$store.getters.can(
                    'cs.tarifarios.createTarifasProduccion'
                  ) &&
                    !_vm.form_tarifa.id) ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.editTarifasProduccion'
                  ) &&
                    _vm.form_tarifa.id)},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_tarifa, "unidad_medida", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.unidades_medida),function(unidad_medida){return _c('option',{key:unidad_medida.numeracion,domProps:{"value":unidad_medida.numeracion}},[_vm._v(" "+_vm._s(unidad_medida.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Clase")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_tarifa.clase_tarifa),expression:"form_tarifa.clase_tarifa"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_tarifa.clase_tarifa.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"disabled":(!_vm.$store.getters.can(
                    'cs.tarifarios.createTarifasProduccion'
                  ) &&
                    !_vm.form_tarifa.id) ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.editTarifasProduccion'
                  ) &&
                    _vm.form_tarifa.id)},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_tarifa, "clase_tarifa", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.clases_tarifas),function(clase_tarifa){return _c('option',{key:clase_tarifa.numeracion,domProps:{"value":clase_tarifa.numeracion}},[_vm._v(" "+_vm._s(clase_tarifa.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_tarifa.estado),expression:"form_tarifa.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_tarifa.estado.$invalid ? 'is-invalid' : 'is-valid',attrs:{"disabled":!_vm.form_tarifa.id ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.createTarifasProduccion'
                  ) &&
                    !_vm.form_tarifa.id) ||
                  (!_vm.$store.getters.can(
                    'cs.tarifarios.editTarifasProduccion'
                  ) &&
                    _vm.form_tarifa.id)},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_tarifa, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.estados),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)])])]),(
            !_vm.$v.form_tarifa.$invalid &&
            (_vm.$store.getters.can('cs.tarifarios.createTarifasProduccion') ||
              _vm.$store.getters.can('cs.tarifarios.editTarifasProduccion'))
          )?_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")])]):_vm._e()])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"Modal_trabajos_detalles","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(_vm._s(_vm.modal_title))]),_c('button',{ref:"closeModalTrabajoDetalle",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal1"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('fieldset',{staticClass:"well card"},[_c('legend',{staticClass:"well-legend text-bold bg-frontera text-light"},[_vm._v(" Detalle Tarifa ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-2"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.flag_form_detalle != 0},on:{"click":function($event){return _vm.getFormStatusDet(1)}}},[_c('i',{staticClass:"fas fa-plus"})])]),_c('div',{staticClass:"col-md-12"},[(_vm.det_tarifa.length > 0 || _vm.flag_form_detalle != 0)?_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm mb-0"},[_vm._m(1),_c('tbody',[(_vm.flag_form_detalle == 1)?_c('tr',[_c('td',{staticClass:"text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_detalle.fecha_ini),expression:"form_detalle.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_detalle.fecha_ini.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.form_detalle.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form_detalle, "fecha_ini", $event.target.value)},function($event){return _vm.validaFechas(null)}]}})]),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_detalle.valor),expression:"form_detalle.valor"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_detalle.valor.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"number"},domProps:{"value":(_vm.form_detalle.valor)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_detalle, "valor", $event.target.value)}}})]),_vm._m(2),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"btn-group"},[(
                              !_vm.$v.form_detalle.$invalid &&
                              _vm.$store.getters.can(
                                'cs.tarifarios.editTarifasProduccion'
                              )
                            )?_c('button',{staticClass:"btn btn-sm bg-success",attrs:{"type":"button"},on:{"click":_vm.saveDetalle}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e(),_c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.getFormStatusDet(0)}}},[_c('i',{staticClass:"fas fa-ban"})])])])]):_vm._e(),_vm._l((_vm.det_tarifa),function(det,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[(index === 0 && _vm.flag_form_detalle == 2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_detalle.fecha_ini),expression:"form_detalle.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_detalle.fecha_ini.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.form_detalle.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form_detalle, "fecha_ini", $event.target.value)},function($event){return _vm.validaFechas(index)}]}}):_vm._e(),_vm._v(" "+_vm._s(index !== 0 || _vm.flag_form_detalle != 2 ? det.fecha_ini : "")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index !== 0 || _vm.flag_form_detalle != 2 ? det.fecha_fin : "")+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(det.valor))]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:[
                            det.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]},[_vm._v(" "+_vm._s(det.nEstado)+" ")])]),_c('td',{staticClass:"text-right"},[(index === 0 && _vm.flag_form_detalle == 0)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.getFormStatusDet(2, index)}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(index === 0 && _vm.flag_form_detalle == 2)?_c('div',{staticClass:"btn-group"},[(
                              !_vm.$v.form_detalle.$invalid &&
                              _vm.$store.getters.can(
                                'cs.tarifarios.editTarifasProduccion'
                              )
                            )?_c('button',{staticClass:"btn btn-sm bg-success",attrs:{"type":"button"},on:{"click":_vm.saveDetalle}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e(),_c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.getFormStatusDet(0)}}},[_c('i',{staticClass:"fas fa-ban"})])]):_vm._e()])])})],2)]):_c('div',{staticClass:"callout callout-warning text-muted m-0"},[_c('h5',{staticClass:"m-0"},[_vm._v("No hay detalles de tarifa registrados!")])])])])]),_c('fieldset',{staticClass:"well card"},[_c('legend',{staticClass:"well-legend text-bold bg-frontera text-light"},[_vm._v(" Tarifa Adicional ")]),(
                _vm.$store.getters.can('cs.tarifarios.showTarifasAdicionales')
              )?_c('CsTarifariosTarifasAdicionales',{ref:"CsTarifariosTarifasAdicionales"}):_vm._e()],1)])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"Modal_formato_masivo"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(_vm._s(_vm.modal_title))]),_c('button',{ref:"closeModalTrabajoFormato",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body pb-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('button',{staticClass:"btn bg-olive text-nowrap",attrs:{"id":"formatoTarifaTransporte","type":"button","data-html":"true","title":"Formato de Traifa de Transporte"},on:{"click":function($event){return _vm.descargarFormatoTarifaProduccion()}}},[_c('i',{staticClass:"fa fa-file-excel mr-1"}),_c('small',[_vm._v("Descargar Formato")])])]),_c('div',{staticClass:"form-group col-md-10"},[_c('label',{staticClass:"m-0"},[_vm._v("Carga masiva de Tarifas")]),_c('input',{ref:"file",staticClass:"form-control btn btn-light col-12",class:_vm.$v.form_file.file.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"file","id":"link_formato","accept":".XLSX, .CSV"},on:{"change":function($event){return _vm.elegirDirectorio()}}})]),(_vm.form_file.file)?_c('div',{staticClass:"form-group col-md-2"},[_c('button',{staticClass:"btn btn-sm bg-danger m-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.deletDoc()}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]):_vm._e(),_vm._m(3)])]),(
            !_vm.$v.form_file.file.$invalid &&
            (_vm.$store.getters.can('cs.tarifarios.createTarifasTransportes') ||
              _vm.$store.getters.can('cs.tarifarios.editTarifasTransportes'))
          )?_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn bg-navy",on:{"click":_vm.cargarTarifas}},[_c('i',{staticClass:"fas fa-upload"})])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Nombre")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Tipo Vehículo o Equipo")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Vehículos / Equipos Izaje")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Tipo Tiempo")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Unidad Medida")]),_c('th',[_vm._v("Clase")]),_c('th',[_vm._v("Vigencia")]),_c('th',{staticClass:"col-1"},[_vm._v("Valor")]),_c('th',{staticClass:"col-1"},[_vm._v("Estado")]),_c('th',{staticClass:"col-1"},[_vm._v("Acciones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"col-3"},[_vm._v("Fecha Inicial")]),_c('th',{staticClass:"col-3"},[_vm._v("Fecha Final")]),_c('th',{staticClass:"col-4"},[_vm._v("Valor")]),_c('th',{staticClass:"col-1"},[_vm._v("Estado")]),_c('th',{staticClass:"col-1"},[_vm._v("Acciones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge badge-success"},[_vm._v("ACTIVO")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-warning alert-dismissible"},[_c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_vm._v(" Atención! ")]),_vm._v(" Cambiar la columna "),_c('strong',[_vm._v("Id")]),_vm._v(" del formato afectará el comportamiento de las liquidaciónes, favor abstenerse de cambiar esta columna. ")])
}]

export { render, staticRenderFns }