<template>
  <div class="row">
    <div
      class="col-md-12 text-right mb-2"
      v-if="
        $store.getters.can('cs.tarifarios.createTarifasProduccion') ||
        $store.getters.can('cs.tarifarios.editTarifasProduccion')
      "
    >
      <div class="btn-group">
        <button
          data-target="#Modal_trabajos"
          data-toggle="modal"
          class="btn btn-primary"
          v-if="$store.getters.can('cs.tarifarios.createTarifasProduccion')"
          @click="getFormTarifa()"
        >
          <i class="fas fa-plus"></i>
        </button>
        <button
          data-target="#Modal_formato_masivo"
          data-toggle="modal"
          class="btn btn-success"
          @click="getFormFormato()"
          v-if="
            $store.getters.can('cs.tarifarios.createTarifasProduccion') ||
            $store.getters.can('cs.tarifarios.editTarifasProduccion')
          "
        >
          <i class="fa fa-file-excel"></i>
        </button>
      </div>
    </div>
    <div class="col-md-12">
      <table
        class="table table-bordered table-responsive table-striped table-hover table-sm mb-0"
        v-if="
          $parent.datos_tarifario &&
          $parent.datos_tarifario.tarifas_trabajos.length > 0
        "
      >
        <thead class="thead-dark">
          <tr>
            <th>Nombre</th>
            <th class="text-nowrap">Tipo Vehículo o Equipo</th>
            <th class="text-nowrap">Vehículos / Equipos Izaje</th>
            <th class="text-nowrap">Tipo Tiempo</th>
            <th class="text-nowrap">Unidad Medida</th>
            <th>Clase</th>
            <th>Vigencia</th>
            <th class="col-1">Valor</th>
            <th class="col-1">Estado</th>
            <th class="col-1">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tarifa in $parent.datos_tarifario.tarifas_trabajos"
            :key="tarifa.id"
          >
            <td>{{ tarifa.nombre }}</td>
            <td>{{ tarifa.nTipoVehEquipo }}</td>
            <td class="text-nowrap">
              {{
                tarifa.equipo_type == 1
                  ? tarifa.nTipoVh + " - " + tarifa.nTipoRemolque
                  : tarifa.nTipoEquipo
              }}
            </td>
            <td class="text-center">{{ tarifa.nTipoTiempoTarifa }}</td>
            <td class="text-center">{{ tarifa.nUnidadMedida }}</td>
            <td>{{ tarifa.nClaseTarifa }}</td>
            <td class="text-center text-nowrap">
              <div v-if="tarifa.detalles.length > 0">
                {{
                  tarifa.detalles[0].fecha_fin
                    ? `${tarifa.detalles[0].fecha_ini} - ${tarifa.detalles[0].fecha_fin}`
                    : `${tarifa.detalles[0].fecha_ini}`
                }}
              </div>
            </td>
            <td class="text-right">
              {{
                tarifa.detalles.length > 0
                  ? formatCurrency("es-CO", "COP", 2, tarifa.detalles[0].valor)
                  : ""
              }}
            </td>
            <td class="text-center">
              <span
                class="badge"
                v-bind:class="[
                  tarifa.estado == 1 ? 'badge-success' : 'badge-danger',
                ]"
              >
                {{ tarifa.nEstado }}
              </span>
            </td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  data-target="#Modal_trabajos"
                  data-toggle="modal"
                  class="btn btn-sm bg-navy"
                  v-if="
                    $store.getters.can('cs.tarifarios.editTarifasProduccion') &&
                    $parent.estado_tarifario == 1
                  "
                  @click="getFormTarifa(tarifa)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  data-target="#Modal_trabajos_detalles"
                  data-toggle="modal"
                  class="btn btn-sm bg-info"
                  v-if="
                    $store.getters.can('cs.tarifarios.editTarifasProduccion')
                  "
                  @click="getDataDet(tarifa)"
                >
                  <i class="fas fa-list"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="callout callout-warning text-muted m-0">
        <h5 class="m-0">No hay tarifas registradas!</h5>
      </div>
    </div>
    <!-- Modal Creación-->
    <div class="modal fade" id="Modal_trabajos">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalTrabajo"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <div class="row justify-content-center">
              <div class="form-group col-md-12">
                <label>Nombre</label>
                <input
                  v-model="form_tarifa.nombre"
                  type="text"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.nombre.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can(
                      'cs.tarifarios.createTarifasProduccion'
                    ) &&
                      !form_tarifa.id) ||
                    (!$store.getters.can(
                      'cs.tarifarios.editTarifasProduccion'
                    ) &&
                      form_tarifa.id)
                  "
                />
              </div>
              <div class="form-group col-md-6">
                <label>Tipo Vehículo/Equipo</label>
                <select
                  v-model="form_tarifa.equipo_type"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.equipo_type.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can(
                      'cs.tarifarios.createTarifasProduccion'
                    ) &&
                      !form_tarifa.id) ||
                    (!$store.getters.can(
                      'cs.tarifarios.editTarifasProduccion'
                    ) &&
                      form_tarifa.id)
                  "
                  @change="resetVehEquip()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="equipo_type in listasForms.equipos_types"
                    :key="equipo_type.numeracion"
                    :value="equipo_type.numeracion"
                  >
                    {{ equipo_type.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Vehículos / Equipos Izaje</label>
                <!-- Lista de Tipos de Vehículos -->
                <select
                  v-model="form_tarifa.equipo_id"
                  v-if="form_tarifa.equipo_type == 1"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.equipo_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can(
                      'cs.tarifarios.createTarifasProduccion'
                    ) &&
                      !form_tarifa.id) ||
                    (!$store.getters.can(
                      'cs.tarifarios.editTarifasProduccion'
                    ) &&
                      form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="(tip_veh, index) in listasForms.tipos_vehiculos"
                    :key="index"
                    :value="tip_veh"
                  >
                    {{ tip_veh.nombre }}
                  </option>
                </select>
                <!-- Lista de Tipos de Equipos -->
                <select
                  v-model="form_tarifa.equipo_id"
                  v-if="form_tarifa.equipo_type == 2"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.equipo_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can(
                      'cs.tarifarios.createTarifasProduccion'
                    ) &&
                      !form_tarifa.id) ||
                    (!$store.getters.can(
                      'cs.tarifarios.editTarifasProduccion'
                    ) &&
                      form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="equip in listasForms.tipos_equipos"
                    :key="equip.numeracion"
                    :value="equip.numeracion"
                  >
                    {{ equip.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Tipo Tiempo</label>
                <select
                  v-model="form_tarifa.tipo_tiempo_tarifa"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.tipo_tiempo_tarifa.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can(
                      'cs.tarifarios.createTarifasProduccion'
                    ) &&
                      !form_tarifa.id) ||
                    (!$store.getters.can(
                      'cs.tarifarios.editTarifasProduccion'
                    ) &&
                      form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_tiempo_tarifa in listasForms.tipo_tiempos_tarifas"
                    :key="tipo_tiempo_tarifa.numeracion"
                    :value="tipo_tiempo_tarifa.numeracion"
                  >
                    {{ tipo_tiempo_tarifa.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Unidad Medida</label>
                <select
                  v-model="form_tarifa.unidad_medida"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.unidad_medida.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can(
                      'cs.tarifarios.createTarifasProduccion'
                    ) &&
                      !form_tarifa.id) ||
                    (!$store.getters.can(
                      'cs.tarifarios.editTarifasProduccion'
                    ) &&
                      form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="unidad_medida in listasForms.unidades_medida"
                    :key="unidad_medida.numeracion"
                    :value="unidad_medida.numeracion"
                  >
                    {{ unidad_medida.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Clase</label>
                <select
                  v-model="form_tarifa.clase_tarifa"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.clase_tarifa.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can(
                      'cs.tarifarios.createTarifasProduccion'
                    ) &&
                      !form_tarifa.id) ||
                    (!$store.getters.can(
                      'cs.tarifarios.editTarifasProduccion'
                    ) &&
                      form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="clase_tarifa in listasForms.clases_tarifas"
                    :key="clase_tarifa.numeracion"
                    :value="clase_tarifa.numeracion"
                  >
                    {{ clase_tarifa.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Estado</label>
                <select
                  v-model="form_tarifa.estado"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.estado.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="
                    !form_tarifa.id ||
                    (!$store.getters.can(
                      'cs.tarifarios.createTarifasProduccion'
                    ) &&
                      !form_tarifa.id) ||
                    (!$store.getters.can(
                      'cs.tarifarios.editTarifasProduccion'
                    ) &&
                      form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              !$v.form_tarifa.$invalid &&
              ($store.getters.can('cs.tarifarios.createTarifasProduccion') ||
                $store.getters.can('cs.tarifarios.editTarifasProduccion'))
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Detalle -->
    <div
      class="modal fade"
      id="Modal_trabajos_detalles"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal1"
              ref="closeModalTrabajoDetalle"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--Detalle Tarifa Producción-->
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Detalle Tarifa
              </legend>
              <div class="row">
                <div class="col-md-12 text-right mb-2">
                  <button
                    class="btn btn-primary"
                    @click="getFormStatusDet(1)"
                    :disabled="flag_form_detalle != 0"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div class="col-md-12">
                  <table
                    class="table table-bordered table-striped table-hover table-sm mb-0"
                    v-if="det_tarifa.length > 0 || flag_form_detalle != 0"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th class="col-3">Fecha Inicial</th>
                        <th class="col-3">Fecha Final</th>
                        <th class="col-4">Valor</th>
                        <th class="col-1">Estado</th>
                        <th class="col-1">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="flag_form_detalle == 1">
                        <td class="text-center">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form_detalle.fecha_ini"
                            :class="
                              $v.form_detalle.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaFechas(null)"
                          />
                        </td>
                        <td class="text-center">
                          <!-- <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_fin"
                          :class="
                            $v.form_detalle.fecha_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas(null)"
                        /> -->
                        </td>
                        <td class="text-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form_detalle.valor"
                            :class="
                              $v.form_detalle.valor.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </td>
                        <td class="text-center">
                          <span class="badge badge-success">ACTIVO</span>
                        </td>
                        <td class="text-right">
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-sm bg-success"
                              v-if="
                                !$v.form_detalle.$invalid &&
                                $store.getters.can(
                                  'cs.tarifarios.editTarifasProduccion'
                                )
                              "
                              @click="saveDetalle"
                            >
                              <i class="fas fa-save"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-danger"
                              @click="getFormStatusDet(0)"
                            >
                              <i class="fas fa-ban"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr v-for="(det, index) in det_tarifa" :key="index">
                        <td class="text-center">
                          <input
                            type="date"
                            v-if="index === 0 && flag_form_detalle == 2"
                            class="form-control form-control-sm"
                            v-model="form_detalle.fecha_ini"
                            :class="
                              $v.form_detalle.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaFechas(index)"
                          />
                          {{
                            index !== 0 || flag_form_detalle != 2
                              ? det.fecha_ini
                              : ""
                          }}
                        </td>
                        <td class="text-center">
                          <!--  <input
                          type="date"
                          v-if="index === 0 && flag_form_detalle == 2"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_fin"
                          :class="
                            $v.form_detalle.fecha_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas(index)"
                        /> -->
                          {{
                            index !== 0 || flag_form_detalle != 2
                              ? det.fecha_fin
                              : ""
                          }}
                        </td>
                        <td class="text-right">{{ det.valor }}</td>
                        <td class="text-center">
                          <span
                            class="badge"
                            v-bind:class="[
                              det.estado == 1
                                ? 'badge-success'
                                : 'badge-danger',
                            ]"
                          >
                            {{ det.nEstado }}
                          </span>
                        </td>
                        <td class="text-right">
                          <div
                            class="btn-group"
                            v-if="index === 0 && flag_form_detalle == 0"
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="getFormStatusDet(2, index)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                          <div
                            class="btn-group"
                            v-if="index === 0 && flag_form_detalle == 2"
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-success"
                              v-if="
                                !$v.form_detalle.$invalid &&
                                $store.getters.can(
                                  'cs.tarifarios.editTarifasProduccion'
                                )
                              "
                              @click="saveDetalle"
                            >
                              <i class="fas fa-save"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-danger"
                              @click="getFormStatusDet(0)"
                            >
                              <i class="fas fa-ban"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="callout callout-warning text-muted m-0">
                    <h5 class="m-0">No hay detalles de tarifa registrados!</h5>
                  </div>
                </div>
              </div>
            </fieldset>
            <!--Tarifa Adicional-->
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Tarifa Adicional
              </legend>
              <CsTarifariosTarifasAdicionales
                ref="CsTarifariosTarifasAdicionales"
                v-if="
                  $store.getters.can('cs.tarifarios.showTarifasAdicionales')
                "
              ></CsTarifariosTarifasAdicionales>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Excel Masivo-->
    <div class="modal fade" id="Modal_formato_masivo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalTrabajoFormato"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <div class="row">
              <div class="form-group col-md-12">
                <button
                  id="formatoTarifaTransporte"
                  type="button"
                  class="btn bg-olive text-nowrap"
                  data-html="true"
                  title="Formato de Traifa de Transporte"
                  @click="descargarFormatoTarifaProduccion()"
                >
                  <i class="fa fa-file-excel mr-1"></i>
                  <small>Descargar Formato</small>
                </button>
              </div>
              <div class="form-group col-md-10">
                <label class="m-0">Carga masiva de Tarifas</label>
                <input
                  type="file"
                  id="link_formato"
                  ref="file"
                  class="form-control btn btn-light col-12"
                  v-on:change="elegirDirectorio()"
                  accept=".XLSX, .CSV"
                  :class="
                    $v.form_file.file.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-2" v-if="form_file.file">
                <button
                  type="button"
                  class="btn btn-sm bg-danger m-4"
                  @click="deletDoc()"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
              <div class="alert alert-warning alert-dismissible">
                <h5>
                  <i class="icon fas fa-exclamation-triangle"></i> Atención!
                </h5>
                Cambiar la columna <strong>Id</strong> del formato afectará el
                comportamiento de las liquidaciónes, favor abstenerse de cambiar
                esta columna.
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              !$v.form_file.file.$invalid &&
              ($store.getters.can('cs.tarifarios.createTarifasTransportes') ||
                $store.getters.can('cs.tarifarios.editTarifasTransportes'))
            "
          >
            <button class="btn bg-navy" @click="cargarTarifas">
              <i class="fas fa-upload"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import CsTarifariosTarifasAdicionales from "./CsTarifariosTarifasAdicionales";

export default {
  name: "CsTarifariosTarifasTrabajo",

  components: {
    CsTarifariosTarifasAdicionales,
  },

  data() {
    return {
      cs_tarifario_id: null,
      cs_tarifa_trabajo_id: null,
      tarifa_estado: null,
      modal_title: "",
      listasForms: {
        equipos_types: [],
        tipo_tiempos_tarifas: [],
        clases_tarifas: [],
        unidades_medida: [],
        estados: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
      },
      form_file: {
        file: null,
      },
      form_tarifa: {},
      flag_form_detalle: 0,
      det_tarifa: {},
      form_detalle: {},
    };
  },

  validations: {
    form_tarifa: {
      nombre: {
        required,
      },
      equipo_type: {
        required,
      },
      equipo_id: {
        required,
      },
      tipo_tiempo_tarifa: {
        required,
      },
      unidad_medida: {
        required,
      },
      clase_tarifa: {
        required,
      },
      estado: {
        required,
      },
    },
    form_detalle: {
      valor: {
        required,
      },
      fecha_ini: {
        required,
      },
      /* fecha_fin: {
        required,
      }, */
      estado: {
        required,
      },
    },

    form_file: {
      file: {
        required,
      },
    },
  },

  methods: {
    getIndex() {
      this.cs_tarifario_id = this.$parent.datos_tarifario.id;
    },

    // Funciones para llenado de las listas
    getEquiposTypes() {
      axios.get("/api/lista/130").then((response) => {
        this.listasForms.equipos_types = response.data;
      });
    },

    getTipoTiemposTarifas() {
      axios.get("/api/lista/165").then((response) => {
        this.listasForms.tipo_tiempos_tarifas = response.data;
      });
    },

    getClasesTarifas() {
      axios.get("/api/lista/166").then((response) => {
        this.listasForms.clases_tarifas = response.data;
      });
    },

    getUnidadesMedida() {
      axios.get("/api/lista/197").then((response) => {
        this.listasForms.unidades_medida = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    async getTipoVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          let lista = [];
          for (let i = 0; i < respuesta.length; i++) {
            lista.push({
              id: respuesta[i].id,
              tipo: "tv",
              tipo_vehi: respuesta[i].id,
              nombre: respuesta[i].nombre,
            });
          }

          axios
            .get("/api/lista/187", {
              params: {
                cod_alterno: 4,
              },
            })
            .then(function (response) {
              let respuestaR = response.data;
              for (let i = 0; i < respuestaR.length; i++) {
                lista.push({
                  id: respuestaR[i].numeracion,
                  tipo: "tr",
                  tipo_vehi: 1,
                  nombre: "CABEZOTE - " + respuestaR[i].descripcion,
                });
              }
            })
            .catch(function (error) {});

          me.listasForms.tipos_vehiculos = lista;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTiposEquipos() {
      axios.get("/api/lista/129").then((response) => {
        this.listasForms.tipos_equipos = response.data;
      });
    },

    // Funciones para los formularios
    getFormTarifa(tarifa = null) {
      this.modal_title = `Crear Tarifa`;
      this.resetFormTarifa();
      if (tarifa) {
        this.modal_title = `Editar Tarifa - ${tarifa.nombre}`;
        this.form_tarifa = {
          id: tarifa.id,
          cs_tarifario_id: tarifa.cs_tarifario_id,
          nombre: tarifa.nombre,
          equipo_type: tarifa.equipo_type,
          equipo_id: tarifa.equipo_id,
          tipo_tiempo_tarifa: tarifa.tipo_tiempo_tarifa,
          clase_tarifa: tarifa.clase_tarifa,
          unidad_medida: tarifa.unidad_medida,
          estado: tarifa.estado,
        };
      }
    },

    resetFormTarifa() {
      this.form_tarifa = {
        id: null,
        cs_tarifario_id: this.cs_tarifario_id,
        nombre: null,
        equipo_type: null,
        equipo_id: null,
        tipo_tiempo_tarifa: null,
        unidad_medida: null,
        clase_tarifa: null,
        estado: 1,
      };
    },

    resetVehEquip() {
      this.form_tarifa.equipo_id = null;
    },

    getDataDet(tarifa) {
      this.modal_title = `Histórico de Tarifa Producción Y Mantenimiento - ${tarifa.nombre}`;
      this.cs_tarifa_trabajo_id = tarifa.id;
      this.tarifa_estado = tarifa.estado;
      this.flag_form_detalle = 0;
      this.det_tarifa = tarifa.detalles;
      this.lenarModalAdicionales(tarifa);
    },

    getFormStatusDet(status, index = null) {
      this.flag_form_detalle = status;
      switch (status) {
        case 1:
          this.form_detalle = {
            id: null,
            cs_tarifa_trabajo_id: this.cs_tarifa_trabajo_id,
            valor: null,
            fecha_ini: null,
            fecha_fin: null,
            estado: 1,
          };
          break;
        case 2:
          this.form_detalle = {
            id: this.det_tarifa[index].id,
            cs_tarifa_trabajo_id: this.det_tarifa[index].cs_tarifa_trabajo_id,
            valor: this.det_tarifa[index].valor,
            fecha_ini: this.det_tarifa[index].fecha_ini,
            fecha_fin: this.det_tarifa[index].fecha_fin,
            estado: this.det_tarifa[index].estado,
          };
          break;
        default:
          break;
      }
    },

    // Funciones para validaciones de los datos
    validaFechas(index) {
      let msg_error = "";
      if (this.form_detalle.fecha_ini) {
        const fecha_menor = new Date(this.form_detalle.fecha_ini);
        if (this.det_tarifa.length > 0) {
          let pos = index == null ? 0 : 1;
          if (this.det_tarifa[pos]) {
            let fecha_limite = null;
            if (this.det_tarifa[pos].fecha_fin) {
              fecha_limite = new Date(this.det_tarifa[pos].fecha_fin);
            } else {
              fecha_limite = new Date(this.det_tarifa[pos].fecha_ini);
            }
            if (fecha_menor <= fecha_limite) {
              this.form_detalle.fecha_ini = null;
              msg_error = `La fecha inicio no puede ser menor a la fecha final de la tarifa actual...`;
            }
          }
        }
        if (this.form_detalle.fecha_ini && this.form_detalle.fecha_fin) {
          const fecha_mayor = new Date(this.form_detalle.fecha_fin);
          // Se valida que la fecha inicial sea menor que la fecha final
          if (fecha_menor > fecha_mayor) {
            this.form_detalle.fecha_fin = null;
            msg_error = `La fecha inicio no puede ser mayor a la fecha final...`;
          }
        }
      }

      if (msg_error) {
        this.$swal({
          icon: "error",
          title: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    // Funciones para las acciones de los formularios
    save() {
      if (!this.$v.form_tarifa.$invalid) {
        this.$parent.cargando = true;
        let obj_equi_vh = this.form_tarifa.equipo_id;
        
        if (obj_equi_vh.tipo == "tv") {
          this.form_tarifa.equipo_id = obj_equi_vh.id;
          this.form_tarifa.tipo_remolque = null;
        } else if (obj_equi_vh.tipo == "tr") {
          this.form_tarifa.equipo_id = 1;
          this.form_tarifa.tipo_remolque = obj_equi_vh.id;
        }
        axios({
          method: "POST",
          url: "/api/cs/tarifarios/tarifas/trabajos",
          data: this.form_tarifa,
        })
          .then((response) => {
            this.$parent.cargando = false;
            if (!response.data.msg_error) {
              this.$refs.closeModalTrabajo.click();
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: "El tarifario se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.form_tarifa.nombre = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveDetalle() {
      if (!this.$v.form_detalle.$invalid) {
        this.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/cs/tarifarios/tarifas/trabajos/detalle",
          data: this.form_detalle,
        })
          .then(() => {
            this.$parent.cargando = false;
            this.$refs.closeModalTrabajoDetalle.click();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "El detalle de la tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    // Funciones Adjuntar Data Masiva
    getFormFormato() {
      this.modal_title = `Formato de Tarifas Producción`;
      this.form_file.file = null;
      document.getElementById("link_formato").value = "";
    },

    elegirDirectorio() {
      this.form_file.file = this.$refs.file.files[0];
    },

    descargarFormatoTarifaProduccion() {
      const me = this;
      me.$parent.cargando = true;
      axios
        .get(`api/cs/tarifarios/tarifas/trabajos`, {
          params: { cs_tarifario_id: me.cs_tarifario_id },
        })
        .then(function (response) {
          let data = response.data;
          me.$parent.cargando = false;
          location.href = data.url;
          me.$swal({
            icon: "success",
            title: data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function (error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    cargarTarifas() {
      this.$parent.cargando = true;
      let me = this;
      let formData = new FormData();
      formData.append("file_formato", this.form_file.file);
      formData.append("cs_tarifario_id", this.cs_tarifario_id);
      axios
        .post("/api/cs/tarifarios/tarifas/trabajos/formato", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let result_content = response.data.plantilla
            ? `<div>
                <p class="text-success">
                  <strong>Se actualizaron ${response.data.plantilla.length} registros.</strong>
                </p>
              </div>`
            : "";

          if (response.data.error && response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach(
              (err) => (result_content += `<div><small>${err}</small></div>`)
            );
          }

          this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: "success",
            showConfirmButton: false,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          });

          me.$parent.cargando = false;
          this.$refs.closeModalTrabajoFormato.click();
          this.$parent.getIndex();
        })
        .catch(function (error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    deletDoc() {
      this.form_file.file = null;
      document.getElementById("link_formato").value = "";
    },

    // Llenar Modal Adicionales
    lenarModalAdicionales(tarifaTrabajo) {
      this.$refs.CsTarifariosTarifasAdicionales.llenaModal(tarifaTrabajo);
    },
  },

  beforeMount(){
    this.getEquiposTypes();
    this.getTipoTiemposTarifas();
    this.getClasesTarifas();
    this.getUnidadesMedida();
    this.getEstados();
    this.getTipoVehiculos();
    this.getTiposEquipos();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>
